<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link
                    class="active"
                    :to="{ name: 'ListTrafficProject' }"
                    >My Projects</router-link
                  >
                </li>
                <li
                  class="breadcrumb-item active"
                  style="display: inline-block"
                >
                  <span aria-current="location"
                    ><span data-v-99912124="">Form validation</span></span
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            size="sm"
            type="neutral"
            @click="$router.push({ name: 'AddTrafficProject' })"
            ><i class="ni ni-fat-add"></i> Thêm dự án</base-button
          >
          <base-button size="sm" type="neutral"
            ><i class="ni ni ni-ui-04"></i> Bộ lọc</base-button
          >
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">
            <strong>Total</strong> <em>Monthly</em>
            <strong>Hits(Page Piews) Volume</strong>
          </h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div
            class="row d-flex justify-content-between align-items-baseline pb-3"
            style="
              border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              margin-bottom: 10px;
            "
          >
            <div class="col-md-4">
              <strong>Total Hits Sent: </strong>
              {{ totalHits | formatNumber }} hits
            </div>
            <div
              class="col-md-8 d-flex flex-row justify-content-end align-items-baseline"
            >
              <div class="d-flex" style="max-width: 300px">
                <el-select v-model="listQuery.startMonth">
                  <el-option
                    v-for="option in months"
                    :key="option"
                    :label="option"
                    :value="option"
                  >
                  </el-option>
                </el-select>

                <el-select v-model="listQuery.endMonth" class="ml-1">
                  <el-option
                    v-for="option in months"
                    :key="option"
                    :label="option"
                    :value="option"
                  >
                  </el-option>
                </el-select>
              </div>

              <base-button
                class="view btn-icon ml-1"
                type="primary"
                icon
                @click="getList"
              >
                <font-awesome-icon icon="filter" inverse /> Lọc
              </base-button>
            </div>
          </div>

          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <highcharts class="hc" :options="chartOptions"></highcharts>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Danh sách dự án</h3>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="listQuery.limit"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="listQuery.search"
                  prepend-icon="fas fa-search"
                  placeholder="Tìm kiếm..."
                  @keyup.enter.native="handleFilter"
                >
                </base-input>
              </div>
            </div>

            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              v-loading="listLoading"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-bind="{
                  label: 'Tên dự án',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <router-link
                    :to="{
                      name: 'ViewTrafficProject',
                      params: { id: scope.row.id },
                    }"
                    >{{ scope.row.name }}</router-link
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Website',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{ scope.row.urls[0] | getDomainFromUrl }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Size',
                  minWidth: 100,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{ scope.row.size | ucFirst }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Cập nhật',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{ scope.row.updated_at | formatDate }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Expires, Days',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <strong class="text-danger" v-if="scope.row.is_expired"
                    >Expired</strong
                  >
                  <strong class="text-success" v-if="scope.row.expire_time">{{
                    scope.row.expire_time | formatExpiredTimes
                  }}</strong>
                </template>
              </el-table-column>

              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{ $index, row }" class="d-flex">
                  <router-link
                    :to="{ name: 'ViewTrafficProject', params: { id: row.id } }"
                  >
                    <base-button
                      class="view btn-link"
                      type="primary"
                      size="sm"
                      icon
                    >
                      <i class="text-white fas fa-edit"></i>
                    </base-button>
                  </router-link>

                  <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link ml-2"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white fas fa-trash"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">Tổng cộng: {{ pagination.total }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import swal from "sweetalert2";
import {
  ucFirst,
  formatDate,
  formatExpiredTimes,
  getDomainFromUrl,
  formatNumberWithCommas,
  parseTime,
} from "../../../utils";

export default {
  name: "ListTrafficProject",
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(endDate.getDate() - 365);

    return {
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      listQuery: {
        page: 1,
        limit: 10,
        search: undefined,
        sortBy: "created_at",
        startMonth: "",
        endMonth: "",
      },
      listLoading: false,
      months: [],
      totalHits: 0,
      chartOptions: {
        title: {
          text: "",
        },
        chart: {
          styledMode: false,
          type: "area",
          height: 300,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          labels: {
            step: 3,
            align: "left",
          },
          gridLineWidth: 1,
          tickmarkPlacement: "on",
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        series: [],
        plotOptions: {
          series: {
            cursor: "pointer",
            color: "#6AB8F7",
            fillOpacity: "0.1",
            marker: {
              lineWidth: 1,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<strong>${this.point.category}:</strong> ${this.point.y} page views`;
          },
          backgroundColor: "rgba(0, 0, 0, .75)",
          borderWidth: 2,
          style: {
            color: "#CCCCCC",
          },
        },
      },
    };
  },
  filters: {
    ucFirst(string) {
      return ucFirst(string);
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatExpiredTimes(seconds) {
      return formatExpiredTimes(seconds);
    },
    getDomainFromUrl(url) {
      return getDomainFromUrl(url);
    },
    formatNumber(num) {
      return formatNumberWithCommas(num);
    },
  },
  created() {
    this.getList();
  },
  watch: {
    "listQuery.limit": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      this.listLoading = true;
      this.listQuery.page = this.pagination.currentPage;
      const { data, pagination, start_month, end_month, months, traffics } =
        await this.$store.dispatch("traffic/fetchProjects", this.listQuery);

      this.tableData = data;
      this.pagination.total = pagination.total;
      this.pagination.currentPage = pagination.from;
      this.listQuery.page = pagination.from;
      this.listQuery.startMonth = start_month;
      this.listQuery.endMonth = end_month;
      this.months = months;
      this.chartOptions.xAxis.categories = traffics.map(
        (traffic) => traffic.label
      );
      this.chartOptions.series = [
        {
          data: traffics.map((traffic) => traffic.hits),
          marker: {
            fillColor: "white",
            lineWidth: 2,
            lineColor: "#6AB8F7",
          },
        },
      ];
      this.totalHits = traffics.reduce((acc, curr) => acc + curr.hits, 0);
      this.listLoading = false;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "Chú ý",
          text: `Xác nhận xoá dòng này?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Huỷ",
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const deleteResult = await this.$store.dispatch(
                "traffic/deleteProject",
                row
              );
              this.$notify({
                message: deleteResult.message,
                timeout: 2000,
                icon: "ni ni-bell-55",
                type: deleteResult.success ? "success" : "danger",
                verticalAlign: "top",
                horizontalAlign: "right",
              });

              this.getList();
            } catch (e) {
              console.log(e.response.data);
            }
          }
        });
    },
    applyDateRange(value) {
      this.query.startDate = parseTime(value.startDate, "{y}-{m}-{d}");
      this.query.endDate = parseTime(value.endDate, "{y}-{m}-{d}");
      const diffInDays =
        (value.endDate - value.startDate) / (1000 * 60 * 60 * 24);
      // Different step size for different date ranges
      this.chartOptions.xAxis.labels.step =
        diffInDays > 90 ? 12 : diffInDays > 60 ? 6 : 3;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
